/**
 *  Initialized Address
 */
const zip = () => {
  var zipInput1 = document.querySelector('.js-zip1');
  var zipInput2 = document.querySelector('.js-zip2');

  zipInput1?.addEventListener('change', ()=> {
    //@ts-ignore
    AjaxZip3.zip2addr('zip1', 'zip2', 'address', 'address', 'address');
  });

  zipInput2?.addEventListener('change', ()=> {
    //@ts-ignore
    AjaxZip3.zip2addr('zip1', 'zip2', 'address', 'address', 'address');
  });
};

export default zip;