/**
 * FAQ
 */
const priceToggle = () => {
  const toggleBtn = document.querySelectorAll('.price__header');
  const toggleName = document.querySelectorAll('.price__item-name');

  if (toggleBtn) {
    for (let i = 0; i < toggleBtn.length; i++) {
      toggleBtn[i].addEventListener('click', () => {
        toggleBtn[i].classList.toggle('hide');
        toggleName[i].classList.toggle('hide');
      });
    }
  }
};

export default priceToggle;