/**
 * FAQ
 */
const fgToggle = () => {
  const toggleButton = document.querySelectorAll('.floor-guide__header');

  if (toggleButton) {
    for (let i = 0; i < toggleButton.length; i++) {
      toggleButton[i].addEventListener('click', () => {
        toggleButton[i].classList.toggle('hide');
      });
    }
  }
};

export default fgToggle;