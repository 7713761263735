import test from './components/test';
import fgToggle from './components/fgToggle';
import zip from './components/zip';
import jsScrollable from './components/jsScrollable';
import priceToggle from './components/priceToggle';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test();
    fgToggle();
    zip();
    jsScrollable();
    priceToggle();
  },
  false
);
