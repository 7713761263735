/**
* Initialize AOS for animations
*/

const jsScrollable = () => {
  //@ts-ignore
  new ScrollHint('.js-scrollable', {
    scrollHintIconAppendClass: 'scroll-hint-img', // white-icon will appear
    applyToParents: true,
    i18n: {
      scrollable: ''
    }
  });
};

export default jsScrollable;